/*
This is where you write custom SASS
*/

@media screen and (max-width: 1199px) {    
    .office-img {
        margin: 30px auto 0 !important;
    }  
    #myModal {
        height: 554px !important;
    }
}

@media screen and (max-width: 991px) {    
    #myModal {
        height: 464px !important;
    }
}

@media screen and (max-width: 767px) {  
    #myModal {
        height: 362px !important;
    }
}
@media screen and (max-width: 576px) {    
    #myModal {
        height: 66vw !important;
    }
}
@media screen and (max-width: 400px) {    
    #myModal {
        height: 71vw !important;
    }
}

$drem: 1.8625rem;
$dvw: 7.35vw;
@media screen and (min-width: 1300px) {
    .home-carousel .display-1 {
        font-size: 7.375rem !important;
    }
}
@media screen and (max-width: 1300px) {
    .home-carousel .display-1 {
        font-size: 6.5rem !important;
    }
}
@media screen and (max-width: 1200px) {
    .home-carousel .display-1 {
        font-size: calc(#{$drem} + #{$dvw})!important;
    }
}

.btn.btn-primary {
    &:hover {
        background: #1C3565 !important;
        border-color: #1C3565 !important;
        color: #FCE4A1 !important;
    }
}

.header-container,
.nav-container,
.tagline-container {
    padding-left: 325px !important;
    padding-right: 325px !important;
    @media screen and (max-width: 1650px) {
        & {
            padding-left: 200px !important;
            padding-right: 200px !important;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-left: 0px  !important;
            padding-right: 0px  !important;
        }
    }
}

.main {
    .wrap {
        float: left;

        p {
            display: inline-block;
            float: left;
        }
    }
    .office-img {
        float: left;
    }
    .vid-wrap {
        float: left;
    }
}


.display-1,
.display-2,
.display-3,
.display-4 {
    font-family: 'mrs-eaves-roman-small-caps', serif;
}

.blue-col {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($primary, .85);
    }
}

header {
    .header-btn {
        background: #14223B;
        border: #14223B;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 0.1em !important;
        &:hover {
            background: lighten(#14223B, 5%);
        }
    }
}

nav {    
    ul#main-menu {
        @media screen and (min-width: 1200px) {
                ul {
                    li {
                        a {
                            &:hover {
                                background: $primary !important;
                                color: $white !important;
                            }
                            &.current,
                            &.highlighted {
                                background: $primary !important;
                                color: $secondary !important;
                        }
                    }
                }
            }
        }
    }
    ul {
        li {
            a {
                font-family: 'mrs-eaves-roman-small-caps', serif;
            }
        }
        ul 
        { 
            li {
                a {
                    font-family: 'mrs-eaves-roman-small-caps', serif;
                }
            }
        }
    }
}

.cta-section {
    .cta-wrap {
        max-width: 420px;
        margin: 0 auto;
        position: relative;
        div .panel-image {
            transition: .3s ease;
        }
        p {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -20px;
                transform: translateX(-50%);
                width: 0%;
                height: 1px;
                background: #fce4a1!important;
                transition: .3s ease;
            }
        }
        &:hover {
            div > .panel-image {
                transform: scale(1.1);

            }
            p:before {
                width: 30%;

            }
        }
        .cta-img-inner {
            overflow: hidden;
            position: relative;

        }
    }
}

section.services-section {
    @media screen and (max-width: 991px) {
        [style*="max-width: 320px"] {
            max-width: 475px !important;
        }
    }
    .service-item  {
        &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: $secondary;
            border-radius: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

section.testimonials {
    blockquote {
        a {
            color: #fff !important;
        }
    }
    footer {
        span.d-block {
            letter-spacing: 0.1em;
            display: none !important;
            &:first-of-type {
                display: block !important;
            }
        }
    }
}

section.contact-section  {
    .contact-wrap {
        @media screen and (max-width: 1199px) {
            max-width: none !important;
        }
    }
    .form-group {
        margin-top: 0px !important;
        margin-bottom: 8px !important;
        input {
            height: 55px;
        }
    }
    [id*="btnSend"] {
        max-width: 175px; 
    }
}

::placeholder {
    text-transform: uppercase;
}

footer {
    color: rgba(255,255,255,0.64) !important;
    a {
        color: rgba(255,255,255,0.64) !important;
        &:hover {
            color: rgba(255,255,255,0.84) !important;
        }
    }
}

section.home-carousel {
    .cover-tagline-small {
        font-size: 36px;
    }
    @media screen and (max-width: 991px) {
        [style*="height: 720px"] {
            height: 500px !important;
        }
        .cover-tagline-small {
            font-size: 30px !important;
        }
    }
    @media screen and (max-width: 576px) {
        .cover-tagline {
            line-height: 50px !important;
        }
        [style*="height: 720px"] {
            height: 350px !important;
        }
        .cover-tagline-small {
            font-size: 25px !important;
        }
    }
}

section.content-section {
    .content-wrapper {
        @media screen and (max-width: 1199px) {
            margin: 0 auto;
        }
    }
    .video-col {
        padding-left: 200px !important;
    }
    @media screen and (max-width: 1600px) {
        .video-col {
            padding-left: 150px !important;
        }
    }
    @media screen and (max-width: 1400px) {
        .video-col {
            padding-left: 120px !important;
        }
    }
    @media screen and (max-width: 1300px) {
        .video-col {
            padding-left: 50px !important;
        }
    }
    @media screen and (max-width: 1300px) {
        .video-col {
            padding-left: 15px !important;
        }
    }
}

body.interior {
    &.page-type-11,
    &.page-type-27,
    &.page-type-40 {
        input, textarea {
            border-color: rgba(0,0,0,.5) !important;
        }
    }
    .card {
        padding: 45px 35px;
        background: #E6E6E6;
        label {
            display: none;
        }
    }
}

.wysiwyg a {
    color: #ddc78b !important;
    transition: .3s ease;
    &:hover {
        color: #F1D68D !important;
    }
}



.play-btn,
.pause-btn {
    width: 78px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
}

.pause-btn {
    opacity: 0;
    transition: .4s ease;
}
.vid-wrap:hover .pause-btn {
    opacity: 1;
}
.vid-wrap a.play-btn:hover,
.vid-wrap a.pause-btn:hover {
    cursor: pointer;
}


section.modal-section {
    overflow-x: hidden;
    overflow-y: auto;
}
section.modal-section #myModal.active {
    display: block;
    opacity: 1;
    visibility: visible;
}

section.modal-section .modal {
    display: none;
    top: 10% !important;
    bottom: 10% !important;
    height: auto;
}

section.modal-section .modal>div {
    padding: 100px 25px;
}

@media screen and (max-width: 767px) {
    section.modal-section .modal>div {
        padding: 40px 15px;
    }
}

section.modal-section a.close {
    color: #000;
    font-size: 26px;
}

section.modal-section a.close:hover,
header .products-popup a.close:hover {
    cursor: pointer;
}

section.modal-section .modal-content-wrapper {
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
    border-radius: 10px;
    background: #fff;
    padding: 20px 20px;
}

section.modal-section .modal-content-wrapper.display-modal {
    opacity: 1;
}

section.modal-section .slick-slider-lightbox .item {
    text-align: center;
}

section.modal-section .slick-slider-lightbox .item h2 {
    text-align: center;
    margin-bottom: 15px;
}

section.modal-section .slick-slider-lightbox .item img {
    margin: 0 auto 10px auto;
}

section.modal-section .slick-prev:before,
.slick-next:before {
    color: #000 !important;
    font-size: 32px !important;
}

section.modal-section .slick-next {
    right: 0px;
    z-index: 14;
}

section.modal-section .slick-prev {
    left: 0px;
    z-index: 14;
}

section.modal-section .item .image-wrapper img {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

section.modal-section {
    position: fixed;
    margin: 0 auto;
    width: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    opacity: 0;
    -webkit-transition: opacity .75s ease;
    transition: opacity .75s ease;
}

section.modal-section .container {
    height: 100%;
}

section.modal-section .row {
    height: 100%;
}

.modal-section,
.modal-section * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.modal-section .slick-prev:before {
    content: '\f104';
    font-family: 'FontAwesome';
    margin-right: -20px;
}

.modal-section .slick-next:before {
    content: '\f105';
    font-family: 'FontAwesome';
    margin-left: -20px;
}

.modal-section .close,
.modal-section .slick-prev:before,
.modal-section .slick-next:before {
    opacity: 0.35;
}

.modal-section .close:hover,
.modal-section .slick-prev:hover:before,
.modal-section .slick-next:hover:before {
    opacity: 1;
}

.modal-section .modal {
    /* max-width: 900px; */
    margin: 0 auto;
}

.modal-section .modal .modal-content-wrapper {
    border-radius: 0;
    padding: 0;
}

.modal-section .modal .modal-content-wrapper .modal-header {
    padding: 25px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: left !important;
}

.modal-section .modal .modal-content-wrapper .modal-header h2 {
    text-align: left !important;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 !important;
    line-height: 1 !important;
    color: #000;
    font-weight: bold;
}

.modal-section .modal .modal-content-wrapper .modal-header .close {
    font-size: 20px;
    position: relative !important;
    display: inline-block;
    vertical-align: middle;
    top: 0 !important;
    float: right;
}

.modal-section .modal .modal-content-wrapper .modal-body .inner-text {
    padding: 0 25px;
}

.modal-section .modal .modal-content-wrapper .modal-body h2 {
    font-size: 40px !important;
    text-transform: uppercase;
    color: #d3883e !important;
    margin: 0 0 10px 0;
}

.modal-section .modal .modal-content-wrapper .modal-body p {
    font-size: 18px;
    margin: 0 0 25px 0;
}

.modal-section .modal .modal-content-wrapper .modal-body .slick-slider-lightbox {
    overflow: hidden;
}
.modal-section .modal .modal-content-wrapper .modal-body .slick-slide {
    padding: 50px 0 0 0;
    font-size: 0;
}

.modal-section .modal .modal-content-wrapper .modal-body .slick-slide .btn {
    width: 100%;
    margin: 50px 0 0 0;
    padding: 20px 20px !important;
}
.modal-section .modal .modal-content-wrapper .modal-body .slick-list {
    -webkit-transition: 0 !important;
    transition: 0 !important;
}

section.modal-section.fade-in .modal {
    -webkit-transition-delay: 1s;
            transition-delay: 1s;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
}

section.modal-section .btn-primary:hover {
    background: #ad7033 !important;
}

section.modal-section {
    display: none;
    transition: .4s ease;
    &.fade-in {
        display: block !important;
        opacity: 1 !important;
    }
}

section.infection-control {
    background: #14223b;
    p {
        font-size: 33px;
        @media screen and (max-width: 1199px) {
            & {
                font-size: 25px !important;
            }
        }
    }
    
}